<template>
  <div class="wrapper-double-content">
    <div class="row flex-grow-1">
      <div class="col d-flex flex-grow-1">
        <main
          :class="!mx_breadcrumb ? 'nav-space' : ''"
          class="wrapper-double-content--main-wrapper-content"
        >
          <div
            v-if="mx_breadcrumb"
            class="portal-content--bread-crumbs bread-crumbs d-flex align-items-center"
          >
            <b-button class="crumb" variant="link" :to="mx_breadcrumbUrl">
              <font-awesome-icon
                class="mr-2"
                :icon="['far', 'arrow-left']"
              />{{ mx_breadcrumb.text }}
            </b-button>
          </div>
          <router-view
            class="main-content"
            :conference="conference"
            :approval-trees="approvalsTrees"
            :conference-loading="conferenceLoading"
            :approval-trees-loading="approvalsTreesLoading"
          />
        </main>
        <ifac-collapsible-side-panel
          :iconList="iconList"
          @icon-click="handleIconClick"
          :headerText="headerText"
        >
          <keep-alive>
            <component
              :is="activeComponent.component"
              v-bind="activeComponent.options"
            />
          </keep-alive>
        </ifac-collapsible-side-panel>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapMutations, mapState } from 'vuex';
import {
  IfacCollapsibleSidePanel,
  IfacApprovalComments,
  IfacConferenceData,
  ifacBreadcrumbMixin,
} from '@ifac/ui';
import Conferences from '@/services/Api/Conferences';

export default {
  name: 'PortalContent',
  mixins: [ifacBreadcrumbMixin],
  components: {
    IfacCollapsibleSidePanel,
    IfacApprovalComments,
    IfacConferenceData,
  },
  data() {
    return {
      sidePanelView: 0,
      approvalListOptions: [],
      conference: null,
      approvalsTrees: null,
      approvalsTreesLoading: false,
      conferenceLoading: false,
    };
  },
  computed: {
    ...mapState({
      sidePanelIsCollapsed: (state) => state.uiHelpers.sidePanelIsCollapsed,
      speechBubbleDisabled: (state) => state.uiHelpers.speechBubbleDisabled,
    }),
    headerText() {
      return this.sidePanelView === 0 ? 'Application' : 'Comments and recommendations';
    },
    iconList() {
      const iconList = [{ icon: ['far', 'tasks'] }];

      if (!this.speechBubbleDisabled) {
        iconList.push({ icon: ['far', 'comment'] });
      }

      return iconList;
    },
    conferenceId() {
      return this.$route?.params?.id || '';
    },
    sidePanelComponents() {
      return [
        {
          component: IfacConferenceData,
          options: {
            data: this.conference,
            isLoading: this.conferenceLoading,
          },
        },
        {
          component: IfacApprovalComments,
          options: {
            data: this.approvalsTrees,
            isLoading: this.approvalsTreesLoading,
            isAdmin: true,
          },
        },
      ];
    },
    activeComponent() {
      return this.sidePanelComponents[this.sidePanelView];
    },
  },
  created() {
    this.fetchConference();
    this.fetchApprovalsTrees();
  },
  methods: {
    ...mapMutations({
      toggleSidePanelCollapse: 'uiHelpers/toggleSidePanelCollapse',
    }),
    handleIconClick(index) {
      if (this.sidePanelIsCollapsed) this.toggleSidePanelCollapse();
      this.sidePanelView = index;
    },
    async fetchConference() {
      this.conferenceLoading = true;
      try {
        const response = await Conferences.get(this.conferenceId);
        const conferenceData = response?.data?.data;

        if (response?.status === 200 && conferenceData) {
          this.conference = conferenceData;
        }
      } catch (error) {
        console.log(error);
      }
      this.conferenceLoading = false;
    },
    async fetchApprovalsTrees() {
      this.approvalsTreesLoading = true;
      try {
        const response = await Conferences.approvalTree(this.conferenceId);
        const approvalsTrees = response?.data?.data;

        if (response?.status === 200 && approvalsTrees) {
          this.approvalsTrees = approvalsTrees.reverse();
        }
      } catch (error) {
        console.log(error);
      }
      this.approvalsTreesLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/base/_variables.scss";
@import "@/assets/styles/base/_mixins.scss";

.wrapper-double-content {
  display: flex;
  position: relative;
  padding: 0;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &--main-wrapper-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    .main-content {
      @include media(tablet) {
        padding-right: 2.5rem;
      }
    }
  }
}

.portal-content {
  &--bread-crumbs {
    margin-left: 0;
    margin-bottom: 0;
  }
}
</style>
