import { DELETE_REASONS as type } from '@/store/types';

const state = {
  data: [
    { id: 'deceased', name: 'Deceased' },
    { id: 'requested', name: 'Requested by the person' },
    { id: 'other', name: 'Other' },
  ],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {};

const getters = {
  data(state) {
    return state.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
