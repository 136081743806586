import { CONFERENCES as type } from '@/store/types';
import Conferences from '@/services/Api/Conferences';
import { ConferenceStatuses } from '@ifac/ui';

const state = {
  data: [],
  loading: false,
  metas: [
    'meta_location',
    'meta_schedule',
    'meta_conference',
    'meta_fees',
    'meta_financial',
    'meta_meeting',
    'meta_accommodation',
    'meta_meeting',
    'meta_sponsoring',
    'meta_people',
    'meta_marketing',
    'meta_publicity',
    'meta_participation',
    'meta_submission',
  ],
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  fetchConferences(
    { commit },
    page = 1,
    perPage = 12,
    search = '',
    sortBy = null,
    direction = 'desc',
    filter = null,
  ) {
    commit(type.LOADING, true);
    Conferences
      .all(
        page,
        perPage,
        search,
        sortBy,
        direction,
        filter,
      )
      .then(({ status, data } = {}) => {
        if (status === 200 && data?.data) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
};

const getters = {
  conferences(state) {
    return state.data;
  },
  metas(state) {
    return state.metas;
  },
  uncompletedSections(state) {
    return (conference) => {
      if (conference) {
        const scopeHasData = conference.meta_meeting?.data?.objective;
        const venueMeetingError = conference.meta_meeting?.errors?.expectedAttendance;

        const uncompletedMetaKeys = state.metas.filter((k) => {
          // Only check the errors in case of the key exists.
          // in other case it has to be always true.
          if (conference[k]) {
            return Object.values(conference[k].errors).length > 0;
          }
          return true;
        });

        const uncompletedSections = [];
        uncompletedMetaKeys.forEach((k) => {
          if (k === 'meta_sponsoring' && !uncompletedSections.includes(1)) {
            uncompletedSections.push(1);
          } else if (
            ['meta_location', 'meta_schedule', 'meta_conference'].includes(k)
            && !uncompletedSections.includes(2)
          ) {
            uncompletedSections.push(2);
          } else if (
            (k === 'meta_meeting' && !scopeHasData)
            && !uncompletedSections.includes(3)
          ) {
            uncompletedSections.push(3);
          } else if (k === 'meta_people' && !uncompletedSections.includes(4)) {
            uncompletedSections.push(4);
          } else if (
            ['meta_fees', 'meta_financial'].includes(k)
            && !uncompletedSections.includes(5)
          ) {
            uncompletedSections.push(5);
          } else if (
            (
              k === 'meta_accommodation'
              || (k === 'meta_meeting' && !!venueMeetingError)
            )
            && !uncompletedSections.includes(6)
          ) {
            uncompletedSections.push(6);
          } else if (['meta_marketing'].includes(k) && !uncompletedSections.includes(7)) {
            uncompletedSections.push(7);
          } else if (
            ['meta_publicity', 'meta_participation'].includes(k)
            && !uncompletedSections.includes(8)
          ) {
            uncompletedSections.push(8);
          } else if (k === 'meta_submission' && !uncompletedSections.includes(9)) {
            uncompletedSections.push(9);
          }
        });

        return uncompletedSections;
      }
      return [1, 2, 3, 4, 5, 6, 7, 8, 9];
    };
  },
  lastSection() {
    return (conference, route) => {
      const currentSection = route.query?.section;
      if (currentSection) return Number(currentSection);
      if (conference) {
        const scopeData = conference.meta_meeting?.data?.objective;
        const venueData = conference.meta_meeting?.data?.expectedAttendance;
        if (conference.meta_participation || conference.meta_publicity) return 9;
        if (conference.meta_marketing) return 8;
        if (conference.meta_accommodation || (conference.meta_meeting && !!venueData)) {
          return 7;
        }
        if (conference.meta_fees || conference.meta_financial) return 6;
        if (conference.meta_people) return 5;
        if (conference.meta_meeting && !!scopeData) {
          return 4;
        }
        if (
          conference.meta_location
          || conference.meta_schedule
          || conference.meta_conference
          || conference.meta_title
        ) {
          return 3;
        }
        if (conference.meta_sponsoring) {
          return 2;
        }
      }
      return 1;
    };
  },
  hasErrors() {
    return (conference) => {
      const metaKeys = Object.keys(conference)
        .filter((k) => k.includes('meta') && !(k === 'meta_fees'));

      const errors = metaKeys.filter((k) => {
        const errorObject = Object.values(conference[k].errors)
          .filter((o) => o[0] && !o[0].includes('mandatory'));

        return errorObject.length > 0;
      });

      return errors.length > 0;
    };
  },
  editableStatusKeys() {
    const {
      approved,
      published,
      released,
      submitted,
    } = ConferenceStatuses;
    return [
      approved.status,
      published.status,
      released.status,
      submitted.status,
    ];
  },
  isEditable(state, getters) {
    return (conference) => getters.editableStatusKeys
      .includes(conference?.status);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
