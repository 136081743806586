import api from '@/services/Api';

class Settings {
  /**
   * Fetch all the Settings
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/settings');
  }

  /**
   * Update a single Setting
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/settings/${payload.id}`, payload);
  }
}

export default Settings;
