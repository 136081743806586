import Keycloak from 'keycloak-js';

export const keycloak = Keycloak({
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
});

export async function KeycloakAuthInit(options) {
  const { store, router, authComplete } = options;

  keycloak.onAuthSuccess = async () => {
    await store.dispatch('auth/setKeycloakTokens', {
      token: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });

    setInterval(() => keycloak.updateToken(30), 100000);

    try {
      // monitor authentication
      store.dispatch('auth/monitorAuthenticated');
    } catch (e) {
      console.error('Keycloak initial error', e);
    }
  };

  keycloak.onAuthLogout = async () => {
    await store.dispatch('auth/resetUser');
    await store.dispatch('auth/setKeycloakTokens', {});

    // redirect user to splash screen
    router.replace({
      name: 'Dashboard',
    });
  };

  keycloak.onAuthRefreshSuccess = async () => {
    await store.dispatch('auth/setKeycloakTokens', {
      token: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });
  };

  // update isAuthenticating status
  store.dispatch('auth/isAuthenticating', true);

  try {
    const keycloakInitConfig = {
      pkceMethod: 'S256',
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      enableLogging: true,
      checkLoginIframe: false,
    };
    await keycloak.init(keycloakInitConfig);

    // fetch user if we are authenticated - do this here rather than in onAuthSuccess()
    // to ensure data is loaded before app is created
    if (keycloak.authenticated) {
      await store.dispatch('auth/setUser');
    } else {
      await store.dispatch('auth/login');
    }
  } catch (e) {
    // ignore exception
  }

  // update isAuthenticating status
  store.dispatch('auth/isAuthenticating', false);

  // update isAuthenticated status
  store.dispatch('auth/setAuthenticated', keycloak.authenticated);

  authComplete();
}
