import { AUTH as type } from '@/store/types';
import Admins from '@/services/Api/Admins';

const state = () => ({
  user: null,
  keycloakProfile: null,
  isAuthenticating: false,
  isAuthenticated: false,
  redirectTo: null,
  keycloak: null,
  tokens: null,
});

const mutations = {
  [type.AUTHENTICATING.SET](state, value) {
    state.isAuthenticating = value;
  },

  [type.AUTHENTICATED.SET](state, value) {
    state.isAuthenticated = value;
  },

  [type.REDIRECT.SET](state, routeName) {
    state.redirectTo = routeName;
  },

  [type.KEYCLOAK.SET](state, keycloak) {
    state.keycloak = keycloak;
  },

  [type.KEYCLOAK.TOKENS.SET](state, tokens) {
    state.tokens = tokens;
  },

  [type.KEYCLOAK.PROFILE.SET](state, keycloakProfile) {
    state.keycloakProfile = keycloakProfile;
  },

  [type.USER.SET](state, user) {
    state.user = user;
  },
};

const actions = {
  async setKeycloakProfile({ commit }) {
    const { $keycloak } = this._vm;
    const profile = await $keycloak.loadUserProfile();
    commit(type.KEYCLOAK.PROFILE.SET, profile);
  },

  async setUser({ commit }) {
    const user = await Admins.me();
    commit(type.USER.SET, user.data.data);
  },

  async setAuthenticated({ commit }, authenticated) {
    commit(type.AUTHENTICATED.SET, authenticated);
  },

  // will keep an eye on $keycloak.authenticated and make sure
  // our store is kept up-to-date with the same value
  async monitorAuthenticated({ dispatch }) {
    const monitor = () => {
      const { $keycloak } = this._vm;
      if ($keycloak.authenticated !== state.isAuthenticated && state.isAuthenticated) {
        dispatch('auth/logout');
      }
    };

    monitor();
    await setInterval(async () => {
      monitor();
    }, 1000 * 10);
  },

  async resetUser({ commit }) {
    commit(type.USER.SET, null);
    commit(type.KEYCLOAK.PROFILE.SET, null);
    commit(type.AUTHENTICATED.SET, false);
  },

  login({ commit }) {
    commit(type.AUTHENTICATING.SET, true);
    // commit(type.REDIRECT.SET, options.redirectAfterAuthRoute);
    this._vm.$keycloak.login({
      redirectUri: window.location.href,
    });
  },

  async logout({ commit }) {
    commit(type.AUTHENTICATING.SET, false);
    await this._vm.$keycloak.logout({
      redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT_URI,
    });
  },

  async redirectAfterAuth({ commit }, routeName) {
    commit(type.REDIRECT.SET, routeName);
  },

  async isAuthenticating({ commit }, isAuthenticating) {
    commit(type.AUTHENTICATING.SET, isAuthenticating);
  },

  async setKeycloakTokens({ commit }, tokens) {
    this._vm.$api.interceptors.request.use(
      async (config) => {
        config.headers.Authorization = `Bearer ${tokens.token}`;
        return config;
      },
    );
    commit(type.KEYCLOAK.TOKENS.SET, tokens);
  },
};

const getters = {
  user: (state) => state.user,
  isAuthenticating: (state) => state.isAuthenticating,
  isAuthenticated: (state) => state.isAuthenticated,
  tokens: (state) => state.tokens,
  keycloakProfile: (state) => state.keycloakProfile,
  redirectRoute: (state) => (state.redirectTo ? state.redirectTo : null),
  isRegistrationComplete: (state) => state.user?.isRegistered,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
