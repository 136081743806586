<template>
  <div id="app" class="d-flex flex-column">
    <main-nav></main-nav>
    <main
      v-if="!needsPortalContent"
      :class="!mx_breadcrumb ? 'nav-space' : ''"
    >
      <div class="bread-crumbs d-flex align-items-center" v-if="mx_breadcrumb">
        <b-button class="crumb" variant="link" :to="mx_breadcrumbUrl">
          <font-awesome-icon
            class="mr-2"
            :icon="['far', 'arrow-left']"
          />{{ mx_breadcrumb.text }}
        </b-button>
      </div>
      <router-view />
    </main>
    <portal-content v-else></portal-content>
    <main-footer v-if="!needsPortalContent"></main-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ifacBreadcrumbMixin } from '@ifac/ui';
import MainNav from '@/views/layouts/MainNav.vue';
import MainFooter from '@/views/layouts/MainFooter.vue';
import PortalContent from '@/views/layouts/PortalContent.vue';
import store from '@/store';

const pjson = require('../package.json');

export default {
  mixins: [ifacBreadcrumbMixin],
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    currentVersion() {
      return {
        app: pjson.version,
        ui: pjson.dependencies['@ifac/ui'],
      };
    },
    needsPortalContent() {
      return ['ApprovalsDetails']
        .includes(this.$route.name);
    },
  },
  components: {
    MainNav,
    MainFooter,
    PortalContent,
  },
  created() {
    if (this.isAuthenticated) {
      store.dispatch('coordinatingCommittees/fetchCoordinatingCommittees');
      store.dispatch('countries/fetchCountries');
      store.dispatch('addressTypes/fetchAddressTypes');
      store.dispatch('contactTypes/fetchContactTypes');
      store.dispatch('genders/fetchGenders');
      store.dispatch('careerStages/fetchCareerStages');
      store.dispatch('industryAcademiaGovernment/fetchIndustryAcademiaGovernment');
      store.dispatch('positions/fetchPositions');
      store.dispatch('trienniums/fetchTrienniums');
      store.dispatch('conferenceTypes/fetchConferenceTypes');
      store.dispatch('venueTypes/fetchVenueTypes');
      store.dispatch('nationalMemberOrganizations/fetchNMOs');
      store.dispatch('positions/fetchPositionsForLiaison');
    }

    console.info('Version', this.currentVersion);
  },
};
</script>

<style>
main {
  padding: 1.875rem;
}
</style>
<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.bread-crumbs {
  padding-left: 0.8rem;
  border-bottom: 1px solid $color-grey-light;
  margin-bottom: 2rem;
  font-size: 14px;

  .btn-link {
    padding: 0.75rem;
    color: inherit;
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
