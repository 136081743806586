import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import allRoutes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById('app')
      .scrollIntoView({ behavior: 'smooth' });
  },
});

/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */
function applyBeforeEnter(routes, beforeEnter) {
  return routes.map((route) => ({ ...route, beforeEnter }));
}

/**
 * Make sure user is logged in
 *
 * @param  {Array} routes
 * @return {Array}
 */
function authGuard(routes) {
  return applyBeforeEnter(routes, async (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
      store.dispatch('auth/login');
    }

    if (to.hash.match(/#state=/)) {
      return next({ path: to.path, query: to.query, replace: true });
    }

    return next();
  });
}

/**
 * Guest routes can be access by anyone
 *
 * @param  {Array} routes
 * @return {Array}
 */
function guestGuard(routes) {
  return applyBeforeEnter(routes, async (to, from, next) => {
    if (to.hash.match(/#state=/)) {
      return next({ path: to.path, query: to.query, replace: true });
    }

    return next();
  });
}

allRoutes({ authGuard, guestGuard }).forEach((route) => {
  router.addRoute(route);
});

export default router;
