<template>
  <div class="main-navbar">
    <div class="navbar-wrapper bg-primary d-flex justify-content-between align-items-center">
      <div class="logo-wrapper">
        <a href="/">
          <img
            src="@/assets/images/logo-oncolour.svg"
            alt="International Federation of Automatic Control"
            class="nav-logo d-none d-lg-block"/>
          <img
            src="@/assets/images/mobile-logo.svg"
            alt="International Federation of Automatic Control"
            class="nav-logo d-lg-none"/>
        </a>
      </div>
      <div class="nav-items-wrapper d-flex" v-if="isAuthenticated">
        <b-button
          v-if="conferencesEnabled"
          to="/conferences"
          class="nav-link mobile-hide text-white"
          variant="link"
          :class="{'active': this.$route.name === 'Conferences'}">
          Conferences
        </b-button>
        <b-button
          to="/affiliates"
          class="nav-link mobile-hide text-white"
          variant="link"
          :class="{'active': this.$route.name === 'Affiliates'}">
          Affiliates
        </b-button>
        <b-button
          to="/users"
          class="nav-link mobile-hide text-white"
          variant="link"
          :class="{'active': this.$route.name === 'Users'}">
          Admin Users
        </b-button>
        <b-button
          to="/settings"
          class="nav-link mobile-hide text-white"
          variant="link"
          :class="{'active': this.$route.name === 'Settings'}">
          Configuration
        </b-button>
        <b-dropdown
          right
          no-caret
          variant="link"
          class="nav-link text-white">
          <template #button-content>
            My account <font-awesome-icon :icon="['far', 'circle-user']"/>
          </template>
          <b-dropdown-item @click="changePassword">Change Password</b-dropdown-item>
          <b-dropdown-item @click="onLogout">Sign Out</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="nav-items-wrapper d-flex" v-else>
        <b-button
          @click="login"
          class="nav-link text-white"
          variant="link"
        >
          Login
        </b-button>
      </div>
    </div>
    <div
      v-if="isAuthenticated"
      class="mobile-nav-items-wrapper bg-primary d-flex d-lg-none overflow-auto"
    >
      <b-button
        v-if="conferencesEnabled"
        to="/conferences"
        class="nav-link text-white"
        variant="link"
        :class="{'active': this.$route.name === 'Conferences'}">
        Conferences
      </b-button>
      <b-button
        to="/affiliates"
        class="nav-link text-white"
        variant="link"
        :class="{'active': this.$route.name === 'Affiliates'}">
        Affiliates
      </b-button>
      <b-button
        to="/users"
        class="nav-link text-white"
        variant="link"
        :class="{'active': this.$route.name === 'Users'}">
        Users
      </b-button>
      <b-button
        to="/settings"
        class="nav-link text-white"
        variant="link"
        :class="{'active': this.$route.name === 'Settings'}">
        Configuration
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Admins from '@/services/Api/Admins';

export default {
  created() {
    this.fetchSettings();
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
      conferencesEnabled: 'settings/conferencesEnabled',
    }),
  },
  methods: {
    ...mapActions({
      fetchSettings: 'settings/fetchSettings',
    }),
    onLogout() {
      this.$store.dispatch('auth/logout');
    },
    async changePassword() {
      try {
        await Admins.updatePassword(this.user.id);
        this.$snack.success({
          text: 'Sent the email to reset the password, check it out!',
        });
      } catch (error) {
        console.error(error);
        this.$snack.success({
          text: 'Problem by sending the email to reset the password, try again please.',
        });
      }
    },
    login() {
      const loginUrl = this.$keycloak.createLoginUrl({ redirectUri: window.location.href });
      window.location.replace(loginUrl);
    },
  },
};
</script>
<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';
.nav-items-wrapper, .mobile-nav-items-wrapper {
  .nav-link {
    font-size: 18px;
    padding: 0.75rem;
    background-color: $brand-dark-blue;
    border-radius: 0;
    border-color: $brand-dark-blue;
    &:not(:last-child) {
      margin-right: 1.563rem;
    }
    &.active {
      color: $color-white;
      border-bottom: $color-white solid 0.2rem;
    }
    &.dropdown-toggle::after {
      display: none;
    }
    svg {
      margin-left: 0.75rem;
    }
    &:focus {
      box-shadow: none;
    }
    .btn {
      font-size: inherit;
      line-height: unset;
      padding: 0;
      color: inherit;
      background-color: inherit;
      border-color: $brand-dark-blue;
      &.btn-link {
        &:hover {
          text-decoration: none;
        }
      }
    }
    @include media(tablet) {
      &.mobile-hide {
        display: none;
      }
    }
  }
}

</style>

<style scoped lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.navbar-wrapper {
  padding: 1.563rem;
  .nav-logo {
    height: 4rem;
  }
  @include media(tablet) {
    padding: 0.75rem;
    .nav-logo {
      height: 3rem;
    }
  }
}
.mobile-nav-items-wrapper {
  padding: 0.75rem;
  border-top: 1px solid $color-grey-lighter;
  border-bottom: 1px solid $color-grey-lighter;
}

</style>
