import api from '@/services/Api';

class Admins {
  /**
   * Fetch all the admins
   * @returns {AxiosPromise<any>}
   */
  static get(
    page = 1,
    perPage = 12,
    sortBy = null,
    direction = 'desc',
  ) {
    page = parseInt(page, 10);
    perPage = parseInt(perPage, 10);
    const params = {
      page,
      perPage,
      sortBy,
      direction,
    };

    // Clean up the empty fields being sent
    Object.keys(params).forEach((k) => !params[k] && params[k] !== undefined && delete params[k]);

    return api.get('/admins', {
      params,
    });
  }

  /**
   * Fetch single affiliate
   * @returns {AxiosPromise<any>}
   */
  static show(id) {
    return api.get(`/admins/${id}`);
  }

  /**
   * Fetch authed admin
   * @returns {AxiosPromise<any>}
   */
  static me() {
    return api.get('/admins/me');
  }

  /**
   * Update a given affiliate
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/admins/${payload.id}`, payload);
  }

  /**
   * Create a mew Admin
   * @returns {AxiosPromise<any>}
   */
  static create(payload) {
    return api.post('/admins', payload);
  }

  /**
   * Update admin password
   * @returns {AxiosPromise<any>}
   */
  static updatePassword(id) {
    return api.put(`/admins/${id}/password`);
  }

  /**
   * Delete a given affiliate
   * @returns {AxiosPromise<any>}
   */
  static delete(id) {
    return api.delete(`/admins/${id}`);
  }
}

export default Admins;
