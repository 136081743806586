import api from '@/services/Api';
import helpers from '@/services/helpers';

class Conferences {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all(
    page = 1,
    perPage = 12,
    search = '',
    sortBy = null,
    direction = 'desc',
    filter = null,
  ) {
    page = parseInt(page, 10);
    perPage = parseInt(perPage, 10);

    // Clean up the empty fields being sent
    const params = helpers.cleanObjectParams({
      page,
      perPage,
      sortBy,
      direction,
      search,
      filter,
    });

    return api.get('/conferences', {
      params,
    });
  }

  /**
   * Get a single Conference
   * @returns {AxiosPromise<any>}
   */
  static get(id) {
    return api.get(`/conferences/${id}`);
  }

  /**
   * Update a single Conference
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/conferences/${payload.id}`, payload);
  }

  /**
   * Exec a Conference action
   * @returns {AxiosPromise<any>}
   */
  static action(payload) {
    return api.post(`/conferences/${payload.id}/${payload.action}`);
  }

  /**
   * Create a conference file
   * @returns {AxiosPromise<any>}
   */
  static uploadFile(payload) {
    return api.post(`/conferences/${payload.id}/files`, payload);
  }

  /**
   * Fetch single
   * @returns {AxiosPromise<any>}
   */
  static approvalTree(id) {
    return api.get(`/conferences/${id}/approvals-tree`);
  }
}

export default Conferences;
