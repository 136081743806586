// routes which require authentication
const authRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
    },
    component: () => import(/* webpackChunkName: "dashboard-index" */ '../views/areas/dashboard/Index.vue'),
  },

  {
    path: '/grid',
    name: 'Grid',
    meta: {
      title: 'Grid demo',
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/areas/demo/Grid.vue'),
  },

  {
    path: '/users',
    name: 'AdminUsers',
    meta: {
      title: 'Admin users',
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/areas/users/Index.vue'),
  },

  {
    path: '/users/create',
    name: 'AdminUsersCreate',
    meta: {
      title: 'Admin users - create new user',
      breadcrumbParent: {
        name: 'AdminUsers',
        text: 'Admin Users',
      },
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/areas/users/Create.vue'),
  },

  {
    path: '/users/:id',
    name: 'AdminUsersEdit',
    meta: {
      title: 'Admin users - edit user',
      breadcrumbParent: {
        name: 'AdminUsers',
        text: 'Admin Users',
      },
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/areas/users/Edit.vue'),
  },

  {
    path: '/affiliates',
    name: 'Affiliates',
    meta: {
      title: 'Affiliates',
    },
    component: () => import(/* webpackChunkName: "affiliates-index" */ '../views/areas/affiliates/Index.vue'),
  },

  {
    path: '/affiliates/:id',
    name: 'AffiliatesView',
    meta: {
      title: 'Affiliates - details',
      breadcrumbParent: {
        name: 'Affiliates',
        text: 'Affiliates',
      },
    },
    component: () => import(/* webpackChunkName: "affiliates-view" */ '../views/areas/affiliates/View.vue'),
  },

  {
    path: '/affiliates/:id/edit',
    name: 'AffiliatesEdit',
    meta: {
      title: 'Affiliates - edit',
      breadcrumbParent: {
        name: 'Affiliates',
        text: 'Affiliates',
      },
    },
    component: () => import(/* webpackChunkName: "affiliates-edit" */ '../views/areas/affiliates/Edit.vue'),
  },

  {
    path: '/settings',
    name: 'Settings',
    meta: {
      title: 'Settings',
    },
    component: () => import(/* webpackChunkName: "settings-index" */ '../views/areas/settings/Index.vue'),
  },

  {
    path: '/conferences',
    name: 'Conferences',
    meta: {
      title: 'Conferences',
    },
    component: () => import(/* webpackChunkName: "conferences-index" */ '../views/areas/conferences/Index.vue'),
  },

  {
    path: '/conferences/:id/overview',
    name: 'ConferencesOverview',
    meta: {
      title: 'Conferences - overview',
      breadcrumbParent: {
        name: 'Conferences',
        text: 'Conferences',
      },
    },
    component: () => import(/* webpackChunkName: "conferences-overview" */ '../views/areas/conferences/Overview.vue'),
  },

  {
    path: '/conferences/:id/details',
    name: 'ConferencesDetails',
    meta: {
      title: 'Conferences - details',
      breadcrumbParent: {
        name: 'ConferencesOverview',
        text: 'Conferences - overview',
      },
    },
    component: () => import(/* webpackChunkName: "conferences-details" */ '../views/areas/conferences/Details.vue'),
  },

  {
    path: '/conferences/:id/reports/:reportId',
    name: 'ConferencesReport',
    component: () => import(/* webpackChunkName: "conferences-report" */ '../views/areas/reports/Edit.vue'),
    meta: {
      breadcrumbParent: {
        name: 'ConferencesOverview',
        text: 'Conferences - overview',
      },
    },
  },

  {
    path: '/conferences/:id/approvals/:approvalId',
    name: 'ApprovalsDetails',
    component: () => import(/* webpackChunkName: "approvals-edit" */ '../views/areas/approvals/Details.vue'),
    meta: {
      breadcrumbParent: {
        name: 'ConferencesOverview',
        text: 'Conferences - overview',
      },
    },
  },

];

// routes which don't require authentication
const guestRoutes = [];

export default ({ authGuard, guestGuard }) => [
  ...authGuard(authRoutes),
  ...guestGuard(guestRoutes),
];
