import api from '@/services/Api';
import { cleanObject } from '@ifac/ui';

class Affiliates {
  /**
   * Fetch all the affiliates
   * @returns {AxiosPromise<any>}
   */
  static get(
    page = 1,
    perPage = 12,
    search = null,
    sortBy = null,
    direction = 'desc',
    filter = null,
    include = [],
  ) {
    page = parseInt(page, 10);
    perPage = parseInt(perPage, 10);

    // Clean up the empty fields being sent
    const params = cleanObject({
      page,
      perPage,
      sortBy,
      direction,
      search,
      filter,
      include,
    });

    return api.get('/affiliates', {
      params,
    });
  }

  /**
   * Fetch all the affiliates using minimal info
   *
   * @returns {AxiosPromise<any>}
   */
  static getMin(
    page = 1,
    perPage = 12,
    search = null,
    sortBy = null,
    direction = 'desc',
    filter = null,
  ) {
    page = parseInt(page, 10);
    perPage = parseInt(perPage, 10);

    // Clean up the empty fields being sent
    const params = cleanObject({
      page,
      perPage,
      sortBy,
      direction,
      search,
      filter,
    });

    return api.get('/affiliates-min', {
      params,
    });
  }

  /**
   * Fetch single affiliate
   * @returns {AxiosPromise<any>}
   */
  static show(id) {
    return api.get(`/affiliates/${id}`);
  }

  /**
   * Update a given affiliate
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/affiliates/${payload.id}`, payload);
  }

  /**
   * Delete a given affiliate
   * @returns {AxiosPromise<any>}
   */
  static delete(affiliateId, payload) {
    return api.delete(`/affiliates/${affiliateId}`, {
      data: payload,
    });
  }

  /**
   * request avatar upload
   * @returns {AxiosPromise<any>}
   */
  static avatarUpload(affiliateId, payload) {
    return api.post(`/affiliates/${affiliateId}/avatars`, payload);
  }

  static updateLoginInfo(affiliateId, payload) {
    return api.post(`/affiliates/${affiliateId}/login-info`, payload);
  }

  /**
   * Fetch single affiliate
   * @returns {AxiosPromise<any>}
   */
  static exportCsv(p) {
    const _api = api;
    _api.responseType = 'arraybuffer'; // 'blob';

    const params = cleanObject({
      ...p,
      page: 1,
      perPage: 1000000,
    });

    return _api.get('/affiliates/export/csv', {
      params,
    });
  }
}

export default Affiliates;
