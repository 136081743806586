import Vue from 'vue';
import moment from 'moment';

import VTooltip from 'v-tooltip';

import VueSnackbar from 'vue-snack';
import 'vue-snack/dist/vue-snack.min.css';

import VCalendar from 'v-calendar';
import Multiselect from 'vue-multiselect';
import PercentageCircle from 'vue-css-percentage-circle';

Vue.use(VCalendar);
Vue.component('multiselect', Multiselect);
Vue.component('percentage-circle', PercentageCircle);

window.moment = moment;
Vue.prototype.$moment = moment;

Vue.use(VTooltip);

Vue.use(VueSnackbar, {
  position: 'bottom-right',
  time: 5000,
});
