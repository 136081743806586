import { CORD_COMMITTEES as type } from '@/store/types';
import CoordinatingCommittees from '@/services/Api/CoordinatingCommittees';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  async fetchCoordinatingCommittees({ commit }) {
    commit(type.LOADING, true);
    CoordinatingCommittees.all()
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
};

const getters = {
  data(state) {
    return state.data;
  },
  technicalCommitteeList(state) {
    const technicalCommittees = [];

    if (state.data) {
      Object.values(state.data).forEach((cc) => {
        Object.values(cc.technicalCommittees).forEach((tC) => {
          const tcExists = technicalCommittees.find((tc) => tc.id === tC.id);

          if (!tcExists) {
            technicalCommittees.push({
              id: tC.id,
              name: tC.name,
              ccNumber: tC.ccNumber,
              number: tC.number,
            });
          }
        });
      });
    }

    return technicalCommittees;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
